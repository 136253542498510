.root{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 180px;
    background: #252a30;
    .content{
        align-items: center;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        display: flex;
        img{
            margin-top: 10px;
            height: 50px;
        }

        span{
            margin: 12px 0;
            color: rgba(255, 255, 255, 0.4);
            font-size: 13px;
            line-height: 1.8;
        }

        .icons{  
            display: flex;
            a{
                opacity: 0.8;
                display: flex;
                align-items: center;
                margin: 0 5px;
                width: 2em;
                height: 2em;
                line-height: 2em;
                text-align: center;
                background-color: #1a1d22;
                color: white;
                border-radius: 50%;
                justify-content: center;
                &:hover {
                    opacity: 1;
                }
            }           
        }

        
    }
}