.root{
    min-height: 154px;
    padding: 0 20px;
    display: flex;
    align-items: center;

    .nav-container{
        display: flex;
        flex: 1;
        justify-content: flex-end;

        a{
            margin-left: 30px;
            text-decoration: none;
            padding: 0px 6px 0px 6px;
            color: #59717F;
            position: relative;
            font-size: initial;

            &:hover{
            color: #21455B;
            }

            &:focus{
            outline: none;
            }

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -3px;
                left: 0;
                background-color: #000;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s;
            }

            &:hover:before, &.active:before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }       
    }

}