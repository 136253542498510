.root{
    margin: 35px 45px;
    margin-bottom: 0;
    .info{
        display: flex;
        img{
            max-height: 150px;
            max-width: 150px;
            border-radius: 50%;
            margin: 15px;
        }
        .details{
            margin: 30px 0 0 20px;
            display: flex;
            flex-direction: column;
            span{
                padding: 0;
                margin: 0;
                color: #21455b;
                line-height: 1.4em;
                letter-spacing: -2px;
                &.position{
                    font-weight: 400;
                    font-size: 1.5em;
                }
                &.name{
                    font-weight: 600;
                    font-size: 2em;
                }
            }
        }
    }
    .divider{
        margin: 10px 0;
        border-top: 2px solid rgba(0, 0, 0, 0.12);
    }

    .additional-info{
        font-size: 1.2em;
        color: #6a6a6a;
        line-height: 1.5em;
        word-spacing: 0.035em;
    }
}