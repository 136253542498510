.root{
    min-height: 100%;
    position: relative;
    .content{
        display: flex;
        height: 100%;
        flex-direction: column;
        padding-bottom: 215px;
        max-width: 850px;
        margin: auto;
    }
}